.message{
    padding: 5px;
    margin: 5px;
    width: fit-content;
}
.message__user{
    margin-left: auto;
    /* color:white; */
    text-align: left !important;

}
.message__userCard{
    background-color: rgb(88, 89, 91) !important;
    
}
.message__guestCard{
    background-color: rgb(190, 30, 45) !important;
   
}
.message_style{
    color: white;
    font-size: small;
}