.card_spacer{
    width: 70vw !important;
    /* position: relative; */
    /* top: 10rem; */
    /* left: 35px; */
}
.orders{
    margin-top: -5vh;
    float: right;
    font-size: 2rem;
    color: #ffa800;
   
}
.completed-orders{
    margin-top: -5vh;
    float: right;
    font-size: 2rem;
    color: #3699ff;
}
.approved-orders{
    margin-top: -5vh;
    float: right;
    font-size: 2rem;
    color: #f64e60;
    
}
.order-detail{
    margin-top: -5vh;
    float: right;
    font-size: 2rem;
    color: #1bc5bd; 
    display: inline;
}
.admin{
    position: relative;
    top: 1.5rem;
}
#order-detai{
   display: inline;
}
