.profile-menu{
    display: flex;
    display: inline-block;
    margin-left: 1rem;

}
.profile-icon{
    width: 50px;
    height: 35px;
    margin-bottom: 8px;
}

#profile-text{
    font-size: 17px;
    color: black;
    font-weight: 500;
    padding: auto;
    
    
}

