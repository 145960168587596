/* .app__form {
  position: fixed;
  bottom: 20px;
  z-index: 1;
  background-color: #e9e9eb;
  display: flex;
  width: 100%;
  margin: 20px;
} */

/* .app_formControl {
  display: flex !important;
  flex-direction: row !important;
}

.app__iconButton {

  align-self: flex-end !important;
}

.app__input {
  height: 50px;
  width: 100%;
} */

/* .chat_input {

place-items: center;
  position: fixed;
  display: flex;
  bottom: 50px;
  z-index: 1;
  justify-content: space-between;
  display:flex;
  height: 60px;
  width:62%;
  border-radius: 10px;
} */

/* .input-text {
  position: fixed;
  bottom: 50px;

  height: 60px;
 
  display:flex;
  width:60%;
  border-radius: 5px;
  
} */
#container{
	/* width:750px;
	height:800px; */
	background:#eff3f7;
	margin:0 auto;
	font-size:0;
	border-radius:5px;
	overflow:hidden;
}
/* aside{
	width:260px;
	height:800px;
	background-color:#3b3e49;
	display:inline-block;
	font-size:15px;
	vertical-align:top;
} */
main{
	/* width:490px;
	height:800px; */
	display:inline-block;
	font-size:15px;
	vertical-align:top;
}

/* aside header{
	padding:30px 20px;
}
aside input{
	width:100%;
	height:50px;
	line-height:50px;
	padding:0 50px 0 20px;
	background-color:#5e616a;
	border:none;
	border-radius:3px;
	color:#fff;
	background-image:url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_search.png);
	background-repeat:no-repeat;
	background-position:170px;
	background-size:40px;
}
aside input::placeholder{
	color:#fff;
}
aside ul{
	padding-left:0;
	margin:0;
	list-style-type:none;
	overflow-y:scroll;
	height:690px;
}
aside li{
	padding:10px 0;
}
aside li:hover{
	background-color:#5e616a;
}
h2,h3{
	margin:0;
}
aside li img{
	border-radius:50%;
	margin-left:20px;
	margin-right:8px;
}
aside li div{
	display:inline-block;
	vertical-align:top;
	margin-top:12px;
}
aside li h2{
	font-size:14px;
	color:#fff;
	font-weight:normal;
	margin-bottom:5px;
}
aside li h3{
	font-size:12px;
	color:#7e818a;
	font-weight:normal;
} */

.status{
	width:8px;
	height:8px;
	border-radius:50%;
	display:inline-block;
	margin-right:7px;
}
.green{
	background-color:#58b666;
}
.orange{
	background-color:#ff725d;
}
.blue{
	background-color:#6fbced;
	margin-right:0;
	margin-left:7px;
}
main{
  width: auto !important;
  height: auto !important;
}
/* main header{
	height:110px;
	padding:30px 20px 30px 40px;
}
main header > *{
	display:inline-block;
	vertical-align:top;
}
main header img:first-child{
	border-radius:50%;
}
main header img:last-child{
	width:24px;
	margin-top:8px;
}
main header div{
	margin-left:10px;
	margin-right:145px;
}
main header h2{
	font-size:16px;
	margin-bottom:5px;
}
main header h3{
	font-size:14px;
	font-weight:normal;
	color:#7e818a;
} */
.tab-head{
  background-color: black;
}

#tbl{
  text-align: center;
}

#chat{
  height: 50vh !important;
	margin:0;
	display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
	height:535px;
	border-top:2px solid #fff;
	border-bottom:2px solid #fff;
}

#chat h2,#chat h3{
	display:inline-block;
	font-size:13px;
	font-weight:normal;
}
#chat h3{
	color:#bbb;
}
#chat .entete{
	margin-bottom:5px;
}
#chat .message{
	color:#fff;
	line-height:25px;
	max-width:90%;
	text-align:left;
	border-radius:5px;
}
/* #chat .me{
	text-align:right;
}
#chat .you .message{
	background-color:#58b666;
}
#chat .me .message{
	background-color:#6fbced;
} */
/* #chat .triangle{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 10px 10px 10px;
} */
/* #chat .you .triangle{
		border-color: transparent transparent #58b666 transparent;
		margin-left:15px;
} */
/* #chat .me .triangle{
		border-color: transparent transparent #6fbced transparent;
		margin-left:375px;
} */
.rowChat{
  flex-wrap: nowrap !important;
}
